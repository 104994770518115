import React, { useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";

const SideBar = () => {
  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.clear();
  };
  const location = useLocation();
  const currentPath = location.pathname;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div className={`wrapper d-flex align-items-stretch ${sidebarOpen ? '!active' : ''}`}>
        <nav id="sidebar" className={`wrapper align-items-stretch ${sidebarOpen ? 'active' : ''}`}>
          <div className="custom-menu">
            <button type="button" id="sidebarCollapse" className="btn btn-primary" onClick={toggleSidebar}>
              <i className="fa fa-bars"></i>
              <span className="sr-only"></span>
            </button>
          </div>
          <div className="p-4 pt-5">
            <h2 className="text-center"><a href="/" className="logo ">SOLO RUN<span>®</span></a></h2>
            <NavLink to="/dashboard">
              <div className={`text-style ${currentPath === '/dashboard' ? 'active' : ''}`}>
                <a href="/dashboard" className="a-color"> <span></span> <span><i className="fa-solid fa-user li_and_a_common_class for_navbar_links me-2"></i></span>  Dashboard</a>
              </div>
            </NavLink>
            <NavLink to="/allusers">
              <div className={`text-style ${currentPath === '/allusers' ? 'active' : ''}`}>
                <a href="/allusers" className="a-color"><span></span> <span><i className="fa-solid fa-user li_and_a_common_class for_navbar_links me-2"></i></span>  All User</a>
              </div>
            </NavLink>

            <div className="text-style">
            <NavLink to="/">
              <div className="footer">
                <button onClick={handleLogout} className="btn w-100 sign-btn_1 sign_btn"><span><i className="fa-solid fa-lock me-3"></i></span>Logout</button>
              </div>
              </NavLink>
            </div>
            <div className="img-set">
              {/* <img className="img-width" src="https://www.accu-chek.com.ph/sites/g/files/iut1061/f/styles/image_300x400/public/dotty-instant-300x400.png?itok=InpeBMqE" alt="" /> */}
            </div>
          </div>
        </nav>
        {/* <!-- Page Content  --> */}
        <div id="content" className="mb-5" style={{ width: '85%' }}>
          <h1 className="text-center mb-4 admin-haeding"></h1>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default SideBar;
