import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom'; // Import useHistory from 'react-router-dom'
// import AdminDashbord from '../adminDasbord/adminDashbord';
const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // Hardcoded static username and password for testing
    const staticUsername = 'admin';
    const staticPassword = 'admin';

    // Check if entered credentials match the hardcoded values
    if (username === staticUsername && password === staticPassword) {
      alert("Login successful")
      navigate('/dashboard'); return
    } else {
      // If unsuccessful, you can display an error message or take other actions
     alert("Invalid credentials")
    }
  };

  return (
    <>
      <section className="container-fluid login-root">
        <div className="login-container">
          <div className="circle circle-one" />
          <div className="form-container">

            <h1 className="opacity login_h1">LOGIN</h1>
            <form autocomplete="on">
              <input
                type="text"
                placeholder="USERNAME"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autocomplete="On"
              />
              <input
                type="password"
                placeholder="PASSWORD"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="button" className="opacity submit_btn" onClick={handleLogin}>
                Login
              </button>
            </form>
          </div>
          <div className="circle circle-two" />
        </div>
        <div className="theme-btn-container" />
      </section>
    </>
  );
};

export default AdminLogin;
