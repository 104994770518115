import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AdminLogin from './pages/AdminLogin';
import SideBar from './Layout/AdminLayout';
import Table from './components/Table';
import ChartWorld from "./components/ChartWorld"
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/dashboard" element={<SideBar />}>
          <Route index element={<ChartWorld />} />
        </Route>
        <Route path="/allusers" element={<SideBar />}>
          <Route index element={<Table />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
