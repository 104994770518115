import React, { useEffect, useState, useRef } from 'react';
import firebaseConnection from '../firebase';
import 'jquery'; // Import jQuery
import 'datatables.net'; // Import DataTables
import $ from 'jquery';

const Table = () => {
  const [userData, setUserData] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = firebaseConnection.collection('users');
        const snapshot = await collectionRef.get();

        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); // Run the effect only once on component mount

  useEffect(() => {
    // Check if DataTable is already initialized on the table
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      // DataTable is already initialized, destroy it before reinitializing
      $(tableRef.current).DataTable().destroy();
    }

    // Initialize DataTables using the imported class
    if (userData.length > 0) {
      // Initialize DataTables using the imported class
      $(tableRef.current).DataTable({
        // DataTables options here
      });
    }
  }, [userData]); // Re-run the effect when userData changes

  return (
        <div className='table-root'>
          <div className='containerTable'>
            <table ref={tableRef} className='leaderBord-Table mytableSort display' id="table_id">
              <thead className='leaderBord-th' style={{ position: '-webkit-sticky' }}>
                <tr>
                  <th>#</th>
                  <th>Player Name</th>
                  <th>Country</th>
                  <th>Score</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((user, index) => (
                  <tr key={user.id} className='leaderBord-tr'>
                    <th scope="row">{index + 1}</th>
                    <td className='table-td leaderbord-td'>{user.name}</td>
                    <td className='table-td leaderbord-td'>{user.country}</td>
                    <td className='table-td leaderbord-td'>{user.highScore}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
};

export default Table;
