

import React, { useEffect, useState, useRef } from 'react';
import { Chart } from 'react-google-charts';
import firebaseConnection from '../firebase';
import Table from "./Table"
import $ from "jquery";
import 'datatables.net'; // Import DataTables
// import 'datatables.net-bs4'; // Import Bootstrap 4 styling for DataTables

const ChartWorld = () => {
  const [chartData, setChartData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userData, setUserData] = useState([]);
  const tableRef = useRef(null);
  const [topPlayer, setTopPlayer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = firebaseConnection.collection('users');
        const snapshot = await collectionRef.get();

        const data = snapshot.docs.map(doc => {
          const countryData = doc.data();
          return [countryData.country, countryData.popularity];
        });

        setChartData([["Country", "Popularity"], ...data]);
        setTotalUsers(snapshot.size); // Set the total number of users
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = firebaseConnection.collection('users');
        const snapshot = await collectionRef.get();

        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []); // Run the effect only once on component mount


  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionRef = firebaseConnection.collection('users');
        const snapshot = await collectionRef.orderBy('highScore', 'desc').limit(1).get();

        const topPlayerData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
        setTopPlayer(topPlayerData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section className='' id=''>
      <div className='row p-3 ms-5 card-rowSyle '>
        <div className='col-md-3 mb-3'>
       <div className="card" >
        <div className="card-body">
          <h5 className="card-title">Total Users</h5>
          <hr/>
          <p className="card-text">
          {totalUsers}
          </p>

        </div>
        </div>

        </div>
        <div className='col-md-3 mb-3'>
       <div className="card" >
        <div className="card-body">
          <h5 className="card-title">Countries</h5>
          <hr/>
          <p className="card-text">
          {chartData.length - 1}
         </p>
        </div>
        </div>

        </div>
        <div className='col-md-3 mb-3'>
       <div className="card" >
        <div className="card-body">
          <h5 className="card-title">Top Countries</h5>
          <hr/>
          <p className="card-text">
          5</p>
        </div>
        </div>

        </div>
        <div className='col-md-3 mb-3'>
       <div className="card" >
        <div className="card-body">
          <h5 className="card-title">Top Player # Rank 1</h5>
          <hr/>
          <p className="card-text cardtextName">
          {topPlayer ? (
            <>
              <p className="card-text cardtextName">{topPlayer.name}
              <br/>
              {topPlayer.country}
              <br/>
              {topPlayer.phoneNumber}
              </p>
            </>
          ) : (
            <p>Loading...</p>
          )}
          <br/>
          {/* {topPlayer.country} */}
          <br/>
          {/* {topPlayer.phoneNumber} */}
          </p>
        </div>
        </div>

        </div>
      </div>
      </section>

      <section className='mt-3 mb-3 ' id='leaderbord'>
        <div className='row mt-2'>
          <div className='col-md-6 set-ScrollBar'>
          <table  className='leaderBord-Table mytableSort' id="dtVerticalScrollExample">
          <thead className='leaderBord-th' style={{position:'-webkit-sticky'}}>
            <th>#</th>
            <th>Player Name</th>
            <th>Country</th>
            <th>Score</th>
          </thead>
          <tbody>
          {userData.map((user, index) => (
              <tr key={user.id} className='leaderBord-tr' >
                <th scope="row">{index + 1}</th>
                <td className='table-td leaderbord-td'>{user.name}</td>
                <td className='table-td leaderbord-td'>{user.country}</td>
                <td className='table-td leaderbord-td'>{user.highScore}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
        {/* Chart */}
         <div className='col-md-6'>
          <div className='col-md-12'>
            <div className='admin-analytics'>
            <table className='leaderBord-Table-chart ms-4 leaderBord-Table-Radious' cellspacing="0" id="dtVerticalScrollExample">
          <thead className='leaderBord-th-chart mb-5'>
            <th className='chart-haeding '>Golobale View</th>
          </thead>
          <tbody>
            <tr >
              <td className='mt-3 '>
              <Chart
                chartEvents={[
                  {
                    eventName: 'select',
                    callback: ({ chartWrapper }) => {
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();
                      if (selection.length === 0) return;
                      const region = chartData[selection[0].row + 1];
                      console.log('Selected: ' + region);
                    },
                  },
                ]}
                chartType='GeoChart'
                width='100%'
                height='400px'
                data={chartData}
                options={{
                  colorAxis: { colors: ['green', 'red'] }, // Customize the colors for the colorAxis
                  tooltip: { isHtml: true },
                }}
              />
              </td>
            </tr>
          </tbody>
        </table>

            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default ChartWorld;

//
// import React, { useEffect, useState, useRef } from 'react';
// import firebaseConnection from '../firebase';
// import $ from 'jquery';
// import 'datatables.net'; // Import DataTables

// const Table = () => {
//   const [userData, setUserData] = useState([]);
//   const tableRef = useRef(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // const collectionRef = firebaseConnection.collection('leaderboard');
//         const collectionRef = firebaseConnection.collection('users');
//         const snapshot = await collectionRef.get();
//         const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setUserData(data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []); // Run the effect only once on component mount

//   useEffect(() => {
//     if (userData.length > 0) {
//       // Check if DataTable is already initialized on the table
//       if ($.fn.DataTable.isDataTable(tableRef.current)) {
//         // DataTable is already initialized, destroy it before reinitializing
//         $(tableRef.current).DataTable().destroy();
//       }

//       // Initialize DataTables using the imported class
//       $(tableRef.current).DataTable({
//         // DataTables options here
//         "order": [[3, "desc"]] // Sort by the fourth column (score) in descending order
//       });
//     }
//   }, [userData]); // Re-run the effect when userData changes

//   return (
//     <div className='table-root'>
//       <div className='containerTable'>
//         <table ref={tableRef} className='leaderBord-Table mytableSort display' id="table_id">
//           <thead className='leaderBord-th' style={{ position: '-webkit-sticky' }}>
//             <tr>
//               <th>#</th>
//               <th>Player Name</th>
//               <th>Country</th>
//               <th>Score</th>
//             </tr>
//           </thead>
//           <tbody>
//             {userData.map((user, index) => (
//               <tr key={user.id} className='leaderBord-tr'>
//                 <th scope="row">{index + 1}</th>
//                 <td className='table-td leaderbord-td'>{user.name}</td>
//                 <td className='table-td leaderbord-td'>{user.country}</td>
//                 <td className='table-td leaderbord-td'>{user.highScore}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Table;
