import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// const firebaseConfig = {
//   apiKey: "AIzaSyCW9w7uw6eJ6DMMwXdAZxFoSBoIf9Yh85A",
//   authDomain: "runninggame-94062.firebaseapp.com",
//   projectId: "runninggame-94062",
//   storageBucket: "runninggame-94062.appspot.com",
//   messagingSenderId: "831309285540",
//   appId: "1:831309285540:web:492f070380b40540de3e31",
//   measurementId: "G-Y8SDX4SXV5"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAjl-9BBMl-1YODIh_XYYTqbsWmM6UJVEw",
  authDomain: "roche-a7505.firebaseapp.com",
  projectId: "roche-a7505",
  storageBucket: "roche-a7505.appspot.com",
  messagingSenderId: "1091335963029",
  appId: "1:1091335963029:web:f5f39206ee6b4d6e29769c",
  measurementId: "G-LE4ML3HHVK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firebaseConnection = firebase.firestore();

export default firebaseConnection;
